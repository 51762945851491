// 深拷贝
export function deepClone(data) {
	try {
		//浅拷贝指向内存地址
		//Object.assets({}) 为浅拷贝
		//Array.cancat([]) 为浅拷贝
		return JSON.parse(JSON.stringify(data));
	} catch (e) {
		console.log(e);
		alert("深拷贝失败 copy error");
		return null;
		//TODO handle the exception
	}
}

// 清除对象的值
export const clearObjectVal = (obj) => {
	return clearOneObjectVal(obj);
}

// 清除对象的值。对象层数不要太多会一直循环
function clearOneObjectVal(obj) {
	let re = obj;
	Object.keys(re).forEach(key => {
		let typeKey = Object.prototype.toString.call(re[key]);
		if (typeKey === '[object String]' || typeKey === '[object Null]' || typeKey === '[object Number]') {
			re[key] = null;
		} else if (typeKey === '[object Object]') {
			clearOneObjectVal(re[key])
		} else if (typeKey === '[object Array]') {
			re[key] = [];
		} else {
			re[key] = null;
		}
	});
	return re;
}

//打开打印
export function openPrint(path) {
	let newIframe = document.createElement('iframe');
	newIframe.id = 'WinPrtIframe';
	newIframe.style.display = 'none';
	// document.getElementById('box').appendChild(newIframe);
	document.body.appendChild(newIframe);
	// 这个地方可能出现跨域操作  '/print' 这个是配置的代理
	newIframe.src = path;
	newIframe.onload = () => {
		if (newIframe.contentWindow.matchMedia) {
			let mediaQueryList = newIframe.contentWindow.matchMedia('print');
			mediaQueryList.addListener((mql) => {
				console.log(mql)
				if (mql.matches) {
					// console.log('打印之前', mql.matches)
					beforePrint();
				} else {
					// console.log('打印之后', mql.matches)
					afterPrint();
				}
			});
		}

		newIframe.contentWindow.onbeforeprint = this.beforePrint;
		// newIframe.contentWindow.onafterprint = this.afterPrint;
		newIframe.contentWindow.print();
	}
}

function beforePrint(e) {
	console.log('beforePrint', e);
	// this.loading = false;
	//开始打印
}

function afterPrint(e) {
	console.log('afterPrint', e);
	//结束打印
}