//element-ui，Table排序格式换
export function FormatTableSort({
	column,
	prop,
	order
}) {
	let orderBy = null; //排序字段
	let sortAsc = null; //desc降序N，asc升序Y

	if ('ascending' == order) {
		// sortAsc = 'asc';//Y
		sortAsc = 'Y'; //Y
	} else if ('descending' == order) {
		// sortAsc = 'desc';//N
		sortAsc = 'N'; //N
	}
	//如果存在排序
	if (sortAsc && prop) {
		// if ('commitDate' == prop) {
		// 	orderBy = 'commit_date'
		// } else if ('exprStatusTime' == prop) {
		// 	orderBy = 'expr_status_time'
		// }
		orderBy = formatStrStyle(prop);
	}

	return {
		orderBy: orderBy ? orderBy : null,
		sortAsc: sortAsc ? sortAsc : null
	}
}

//第二种排序拼接方式
export function FormatTableSort2({
	column,
	prop,
	order
}) {
	let orderBy = null; //排序字段
	let sortAsc = null; //desc降序，asc升序

	if ('ascending' == order) {
		// sortAsc = 'asc';//Y
		sortAsc = 'asc'; //Y
	} else if ('descending' == order) {
		// sortAsc = 'desc';//N
		sortAsc = 'desc'; //N
	}
	//如果存在排序
	if (sortAsc && prop) {
		// if ('commitDate' == prop) {
		// 	orderBy = 'commit_date'
		// } else if ('exprStatusTime' == prop) {
		// 	orderBy = 'expr_status_time'
		// }
		orderBy = prop + ' ' + sortAsc;
	}

	return {
		orderBy: orderBy ? orderBy : null,
		sortAsc: null
	}
}


//驼峰式命名转化为下划线小写字母命名，例如：commitDate->commit_date
function formatStrStyle(arg) {
	let str = arg.split('');
	// str.charCodeAt()：将字符串str转换为ASCII码 / Unicode 编码
	// String.fromCharCode(strCode):将ASCII码转成对应的字符串
	// toLowerCase()转换成小写 
	// toUpperCase()转换成大写
	//     var str = window.prompt('请输入一个字符');
	//         var strCode = str.charCodeAt();
	//         if(strCode >= 65 && strCode <= 90){
	//             document.write('你输入的是英文大写字母:' + str);
	//         }else if(strCode >= 97 && strCode <= 122){
	//             document.write('你输入的是英文小写字母:' + str);
	//         }else{
	//             document.write('你输入的是其他符号:' + str);
	//         }
	for (let i = 0, ln = str.length; i < ln; i++) {
		let strCode = str[i].charCodeAt();
		if (strCode >= 65 && strCode <= 90) {
			// document.write('你输入的是英文大写字母:' + str);
			str[i] = '_' + str[i].toLowerCase();
		}
		// if (str[i].charAt() >= "a" && str[i].charAt() <= "z") {
		// 	// str[i] = str[i].toUpperCase();
		// 	// console.log(str[i].toLowerCase());
		// 	str[i] = '_'+str[i].toUpperCase();
		// } else {
		// 	str[i] = str[i].toLowerCase();
		// 	// str[i] = '_'+str[i].toLowerCase();
		// }
	}
	return str.join('');
}



// 深拷贝
export function deepClone(data) {
	try {
		//浅拷贝指向内存地址
		//Object.assets({}) 为浅拷贝
		//Array.cancat([]) 为浅拷贝
		return JSON.parse(JSON.stringify(data));
	} catch (e) {
		console.log(e);
		alert("深拷贝失败 copy eoor");
		return null;
		//TODO handle the exception
	}
}

// 清除对象的值
export const clearObjectVal = (obj) => {
	return clearOneObjectVal(obj);
}
// 清除对象的值。对象层数不要太多会一直循环
function clearOneObjectVal(obj) {
	let re = obj;
	Object.keys(re).forEach(key => {
		let typeKey = Object.prototype.toString.call(re[key]);
		if (typeKey === '[object String]' || typeKey === '[object Null]' || typeKey === '[object Number]') {
			re[key] = null;
		} else if (typeKey === '[object Object]') {
			clearOneObjectVal(re[key])
		} else if (typeKey === '[object Array]') {
			re[key] = [];
		} else {
			re[key] = null;
		}
	});
	return re;
}


export function getFileType(fileNameStr) {
	if (!fileNameStr) {
		return '';
	}
	//截取文件名末尾字符串
	// let fileNameStr = "asasdadsadasdaaaaa.png";
	let fileName = fileNameStr.substring(fileNameStr.length-10,fileNameStr.length);
	// console.log('fileName',fileName);
	// 后缀获取
	let suffix = '';
	// 获取类型结果
	let result = '';
	try {
		const flieArr = fileName.split('.');
		suffix = flieArr[flieArr.length - 1];
	} catch (err) {
		suffix = '';
	}
	// fileName无后缀返回 false
	if (!suffix) {
		return false;
	}
	suffix = suffix.toLocaleLowerCase();
	// 图片格式'png', 'jpg', 'jpeg', 'bmp', 'gif' // bmp、jpg、jpeg、gif、png、ico、emf、wmf
	const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif','ico','emf','wmf'];
	// 进行图片匹配
	result = imglist.find(item => item === suffix);
	if (result) {
		return 'image';
	}
	// 匹配 pdf
	const pdflist = ['pdf'];
	result = pdflist.find(item => item === suffix);
	if (result) {
		return 'pdf';
	}
	// 匹配txt
	const txtlist = ['txt'];
	result = txtlist.find(item => item === suffix);
	if (result) {
		return 'txt';
	}
	// 匹配 excel
	const excelist = ['xls', 'xlsx'];
	result = excelist.find(item => item === suffix);
	if (result) {
		return 'excel';
	}
	// 匹配 word
	const wordlist = ['doc', 'docx'];
	result = wordlist.find(item => item === suffix);
	if (result) {
		return 'word';
	}
	// 匹配 ppt
	const pptlist = ['ppt', 'pptx'];
	result = pptlist.find(item => item === suffix);
	if (result) {
		return 'ppt';
	}
	// 匹配 视频
	const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
	result = videolist.find(item => item === suffix);
	if (result) {
		return 'video';
	}
	// 匹配 音频
	const radiolist = ['mp3', 'wav', 'wmv'];
	result = radiolist.find(item => item === suffix);
	if (result) {
		return 'radio';
	}
	// 其他 文件类型
	return 'other';
}
